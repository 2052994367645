@import url("https://files.alpaca.markets/fonts/BROmega/stylesheet.css");
@import url("https://files.alpaca.markets/fonts/Formular/stylesheet.css");

/** todo: add stylesheet.css for Roboto Mono to files.alpaca.markets **/
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

:root {
  --color-black: #0e0e20;
  --color-white: #ffffff;
  --color-gray-10: #f7f7f7;
  --color-gray-20: #e4e4e4;
  --color-gray-30: #dcdcdc;
  --color-gray-40: #cfcfcf;
  --color-gray-50: #929292;
  --color-gray-60: #7a7a7a;
  --color-gray-70: #626262;
  --color-gray-80: #4a4a4a;
  --color-gray-90: #313131;
  --color-gray-100: #191919;
  --color-yellow-10: #fffdf0;
  --color-yellow-20: #feefaa;
  --color-yellow-30: #fde780;
  --color-yellow-40: #fcdf55;
  --color-yellow-50: #fcd72b;
  --color-yellow-60: #e8c003;
  --color-yellow-70: #ae9003;
  --color-yellow-80: #746002;
  --color-yellow-90: #3a3001;
  --color-yellow-100: #1d1800;
  --color-purple-10: #f6f2ff;
  --color-purple-20: #d5c1ff;
  --color-purple-30: #c7adff;
  --color-purple-40: #ab83ff;
  --color-purple-50: #8f5aff;
  --color-purple-60: #7331ff;
  --color-purple-70: #5d27ce;
  --color-purple-80: #461d9c;
  --color-purple-90: #30146b;
  --color-purple-100: #250f52;
  --color-green-10: #f2fffc;
  --color-green-20: #bef4e6;
  --color-green-30: #a0f0d9;
  --color-green-40: #87ebce;
  --color-green-50: #74e7c1;
  --color-green-60: #17daac;
  --color-green-70: #18c69d;
  --color-green-80: #14a381;
  --color-green-90: #0f7059;
  --color-green-100: #094638;
  --color-red-10: #fff2f2;
  --color-red-20: #ffc0c0;
  --color-red-30: #ffabab;
  --color-red-40: #f89898;
  --color-red-50: #ff8686;
  --color-red-60: #ff6666;
  --color-red-70: #eb3d31;
  --color-red-80: #c4291c;
  --color-red-90: #83180e;
  --color-red-100: #410704;
  --color-blue-10: #eef5ff;
  --color-blue-20: #9fc6ff;
  --color-blue-30: #87b7ff;
  --color-blue-40: #62a1ff;
  --color-blue-50: #3f8cff;
  --color-blue-60: #0d6efd;
  --color-blue-70: #0b62e1;
  --color-blue-80: #084fb8;
  --color-blue-90: #033989;
  --color-blue-100: #052a61;
  --color-orange-10: #fff7f0;
  --color-orange-20: #fcdbc0;
  --color-orange-30: #fcd1ad;
  --color-orange-40: #ffc696;
  --color-orange-50: #ffb679;
  --color-orange-60: #ff9e4d;
  --color-orange-70: #f08234;
  --color-orange-80: #ba6124;
  --color-orange-90: #7c4014;
  --color-orange-100: #3e2006;
  --color-pink-10: #fcf0ff;
  --color-pink-20: #f1afff;
  --color-pink-30: #ec92ff;
  --color-pink-40: #e87bff;
  --color-pink-50: #e363ff;
  --color-pink-60: #d72bfc;
  --color-pink-70: #b823d8;
  --color-pink-80: #931cac;
  --color-pink-90: #6d1481;
  --color-pink-100: #410b4d;
}
